import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Katrina = () => {
	const { katrina: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Katrina Rogers`]}
				description="About Dr Katrina Rogers"
			/>
			<ProfilePage
				title="Dr Katrina Rogers - Gastroenterologist"
				name="Dr Katrina Rogers"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of general and luminal gastroenterology including inflammatory bowel disease"
				education={[
					"Undergraduate: University of Western Australia",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Fiona Stanley Hospital, Perth, Western Australia",
				]}
				description={[
					"Dr Katrina Rogers is a gastroenterologist who offers comprehensive and holistic care for her patients. She has special interests in treating patients with irritable bowel syndrome, inflammatory bowel disease, eosinophilic oesophagitis and screening for bowel cancer.",
					"She offers her patients practical advice for the management of common gastrointestinal complaints and takes time to explain their conditions and different treatment approaches.",
					"Katrina is committed to providing high quality gastroscopy and colonoscopy services.",
				]}
				honours={[
					"",
				]}
				background="Katrina undertook her medical training at the University of Western Australia, graduating in 2014. She completed her gastroenterology training at Sir Charles Gairdner Hospital, Royal Perth Hospital and Fiona Stanley Hospital.  She is a Fellow of the Royal Australian College of Physicians (FRACP)."
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Public appointment - Sir Charles Gairdner Hospital, Perth",
					"Public appointment - Osborne Park Hospital, Perth",
				]}
				memberships={[
					"The Royal Australasian College of Physicians (RACP)",
					"Gastroenterological Society of Australia (GESA)",
					"Conjoint Committee for the Recognition of Training in Gastrointestinal Endoscopy",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
					"Australian Medical Association (AMA)",
				]}
				personal={[
					"I enjoy exploring Western Australia’s stunning coastline with my family. Holidaying in Exmouth is one of my favourites.",
				]}
				publications={[
					{
						publicationTitle: "Patient characteristics, clinical presentations, endoscopic features and treatments of Eosinophilic Oesophagitis at a tertiary hospital in Western Australia over a 13-year period.",
						authors: "Rogers K, Halder A, Cai R, Inoue M, Janjua M, Muwanwella N, Picado S, Ragunath K, Siah C.",
					},
					{
						publicationTitle: "The accuracy of administrative health data for identifying patients with rheumatoid arthritis: a retrospective validation study using medical records in Western Australia",
						authors: "Almutairi K, Inderjeeth C, Preen S, Keen H, Rogers K, Nossent J.",
					},
					{
						publicationTitle: "Associations between Colour Vision and Myopia in an Australian Childhood Population Sample. ",
						authors: "Rogers K, Samarawickrama C, Cosstick M, Rose K, Mitchell P.",
					},
				]}
			/>
		</>
	);
};

export default Katrina;
